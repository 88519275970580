.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  margin-top: 70px; /* Ajusta el valor según la altura de tu barra de navegación */
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}
.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}
.bg-card {
  background: linear-gradient(to top left, #ffffff, #d0f2fc);
}

.aqua--marker {
  background-image: url("./Assets/Marca\ de\ agua\ 2.jpg");
  background-repeat: repeat; /* o repeat-x o repeat-y según tus preferencias */
  background-attachment: fixed; /* Esto fija la imagen de fondo */
}

.aqua--marker-services {
  background-image: url("./Assets/Marcadeagua/3.jpg");
  background-repeat: repeat; /* o repeat-x o repeat-y según tus preferencias */
  background-attachment: fixed; /* Esto fija la imagen de fondo */
}

.miniaqua--marker {
  background-image: url("./Assets/Marcadeagua/Tirilla\ Marca\ de\ agua\ Asegurar\ LTDA\ \(PARA\ DIVISIONES\ \).jpg");
  background-repeat: repeat;
  background-attachment: fixed;
}

.custom-pointer {
  cursor: pointer;
}

.letra-circle {
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: bold;
  margin-right: 10px;
  color: #02468e;
  transition: box-shadow 0.3s ease;
}

.letra-circle.selected {
  text-shadow: 0 3px 5px rgba(6, 8, 10, 0.5);
  text-decoration: underline yellow;
}

.letra-carta-texto {
  font-size: 45px;
  font-weight: bold;
  color: #00254c;
}

.letra-carta-titulo {
  font-size: 58px;
  font-weight: bold;
  color: #02468e;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.img-container--service {
  position: relative; /* Establecer posición relativa para que el elemento ::after sea relativo a este contenedor */
}

.img-container--service::after {
  content: ""; /* Agregar contenido pseudo-elemento */
  position: absolute; /* Establecer posición absoluta para que se superponga a la imagen */
  top: 0;
  right: 0; /* Colocar el gradiente en el lado derecho */
  bottom: 0;
  width: 7%; /* Ancho del área difuminada (ajústalo según sea necesario) */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(144, 142, 142, 0.333)
  ); /* Gradiente transparente a blanco */
  pointer-events: none; /* Permitir que los eventos pasen a través del pseudo-elemento */
}

.img-fluid--service {
  width: 100%; /* Para asegurarse de que la imagen ocupe todo el ancho del contenedor */
  height: 100%; /* Para asegurarse de que la imagen ocupe todo el alto del contenedor */
  object-fit: cover; /* Para recortar la imagen manteniendo su relación de aspecto */
}

.container--service-prop {
  display: flex;
  flex-wrap: wrap;
}

.service--prop-item {
  position: relative;
  margin: 10px;
  width: 80px; /* Ajustar el tamaño del recuadro según sea necesario */
  height: 100px; /* Ajustar el tamaño del recuadro según sea necesario */
  border: 2px solid rgba(203, 203, 203, 0.091);
  border-radius: 12px;
  overflow: hidden;
  transition: all ease 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title--service,
.description--service {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s; /* Transición para suavizar la aparición */
}

.service--prop-item:hover .title--service,
.service--prop-item:hover .description--service {
  opacity: 1; /* Mostrar título y descripción al pasar el mouse */
}

.letter {
  font-size: 58px;
  font-weight: bold;
  color: #02468e;
  transition: all ease 0.4s; /* Agregar transición para una animación suave */
}

.service--prop-item:hover {
  width: 400px; /* Expandir el recuadro al pasar el mouse */
  height: 300px;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-content {
  text-align: center;
  background-color: #f8d7da; /* Rojo suave para el fondo */
  border: 1px solid #f5c6cb; /* Borde rojo */
  width: 100%;
  height: 20vh;
}

.body--card-profile {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  width: 100%;
  background: linear-gradient(to right, 
    #0f3057 0%, 
    #185b9d 4.35%, 
    #1a69b0 8.7%, 
    #1c77c2 13.05%, 
    #1e85d4 17.4%, 
    #2193e7 21.75%, 
    #36a2ec 26.1%, 
    #4cb2f1 30.45%, 
    #63c1f6 34.8%, 
    #79d1fb 39.15%, 
    #90e0ff 43.5%, 
    #a6efff 47.85%, 
    #bdfefe 52.2%, 
    #90e0ff 56.55%, 
    #79d1fb 60.9%, 
    #63c1f6 65.25%, 
    #4cb2f1 69.6%, 
    #36a2ec 73.95%, 
    #2193e7 78.3%, 
    #1e85d4 82.65%, 
    #1c77c2 87%, 
    #1a69b0 91.35%, 
    #185b9d 95.7%, 
    #0f3057 100%
  );
  background-size: 2300% auto; /* Incrementamos el tamaño del gradiente para asegurar una transición suave */
  animation: animateBackground 30s linear infinite; /* Ajustamos la duración de la animación para que sea más lenta */
}

@keyframes animateBackground {
  0% {
    background-position: 0 50%;
  } /* Comienza desde la posición inicial */
  100% {
    background-position: 100% 50%;
  } /* Termina en la misma posición que el inicio */
}

.p--profile {
  margin: 10px;
  font-weight: auto;
}

.profile--card {
  display: inline-block;
  perspective: 1000px;
  width: 100%; /* Cambiar el ancho a 100% */
  max-width: 300px; /* Establecer un ancho máximo */
  height: 500px; /* Cambiar la altura a automática */
  margin-bottom: 20px; /* Agregar un margen inferior */
}

.profile--card .profile--card-front header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-bottom: 15px;
}

.profile--card .profile--card-back header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 5px;
}

.profile--card img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
}

.profile--card .profile--card-front,
.profile--card .profile--card-back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backface-visibility: hidden;
  border-radius: 10px;
  padding: 25px;
  transition: transform 0.6s;
  background: linear-gradient(to bottom left, #fff 50%, #f4cd2d 50%);
}

.profile--card-back {
  transform: rotateY(180deg);
}

.flip-arrowed ~ .profile--card {
  transform: rotateY(-180deg);
}

.flip-arrow {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  font-size: 24px;
}

.profile--card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.profile--card.flipped .profile--card-inner {
  transform: rotateY(180deg);
}

.profile--card-front,
.profile--card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-arrow--front {
  position: absolute;
  bottom: 0px;
  right: 30px; /* Ajusta el valor según sea necesario */
  cursor: pointer;
}

.flip-arrow--back {
  position: absolute;
  bottom: 0px;
  left: 30px; /* Ajusta el valor según sea necesario */
  cursor: pointer;
}

/* Contenedor principal */
.pse-container {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  max-width: 1200px;
  width: 100%;
}

/* Estilo para las tarjetas */
.custom-card {
  background: #2c2a29;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.custom-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

/* Encabezado de la imagen */
.header-container {
  padding: 10px;
}

.header-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.header-container:hover .header-image {
  transform: scale(1.05);
}

/* Botón personalizado */
.btn-primary-custom {
  background-color: #ff6b6b;
  border: none;
  color: white;
  padding: 10px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-primary-custom:hover {
  background-color: #ff3b3b;
  transform: translateY(-3px);
}

.btn-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

/* Descripción dentro de la tarjeta */
.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #ddd;
  padding: 5px;
}

.waybox-button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background-color: rgb(26, 69, 148);
  border: 0px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 14px;
  padding: 0px 16px;
  color: white;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;
}

.qr-image {
  max-width: 300px; /* Tamaño para tablet y desktop */
}

@media (max-width: 768px) {
  .qr-image {
    max-width: 100px; /* Tamaño para dispositivos móviles */
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Fondo oscuro semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Asegúrate que esté sobre todo */
}

.modal-content {
  background: #fff;
  padding: 200px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.modal-image {
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  padding-top: 200px;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
}